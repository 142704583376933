import React from 'react';
import SideBarNav from "./SideBarNav"
import SideBarNavMobile from "./SideBarNavMobile"
import "../styles/index.scss"

export default function Layout({ children }) {
  return (
    <div id="grid-wrapper">
      <section id="sidebar">
        <SideBarNav />
        <SideBarNavMobile />
      </section>
      {children}
    </div>
  )
}