import React from "react"
import { Link } from "gatsby"

export default function SideBarNav() {
  return (
    <nav className="pt-3 pt-lg-6 d-none d-lg-block">
      <div id="sticky-nav">
        <Link to={'/'}><svg viewBox="-1 0 70 70" width="70" height="70" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><circle fill="#4F81FF" cx="30" cy="30" r="30"/><g fill="#FFF" fillRule="nonzero"><path d="M21.377 39.968a.79.79 0 0 1-1.447-.63l9.34-21.445a.79.79 0 0 1 1.448 0l9.341 21.444a.79.79 0 0 1-1.448.63l-8.617-19.782-8.617 19.783z"/><path d="M24.802 30.79a.79.79 0 0 1 0-1.58h24.935a.79.79 0 0 1 .79.79c0 11.336-9.19 20.526-20.527 20.526-11.336 0-20.526-9.19-20.526-20.526S18.664 9.474 30 9.474c7.88 0 14.96 4.481 18.4 11.417a.79.79 0 1 1-1.415.701A18.945 18.945 0 0 0 30 11.052c-10.464 0-18.947 8.484-18.947 18.948S19.536 48.947 30 48.947c10.2 0 18.517-8.06 18.931-18.158H24.802z"/></g></g></svg></Link>
        <ul className="list-nostyle mt-4">
          <li>
            <Link to="/work" activeClassName="active" partiallyActive={true}>Work</Link>
          </li>
          <li>
            <Link to="/writing" activeClassName="active" partiallyActive={true}>Writing</Link>
          </li>
          <li>
            <Link to="/about" activeClassName="active">About</Link>
          </li>
        </ul>
      </div>
    </nav>
  )
}
